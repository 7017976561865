






































import Vue from 'vue';
import { Component } from 'vue-property-decorator';
import { namespace } from 'vuex-class';
import { Pending, Cancelled } from '@/components/subscriptions';
import { SubscriptionStatus } from '@/store/modules/auth/state';

const sSettings = namespace('settings');

@Component({
  components: {
    Pending,
    Cancelled,
  },
  data() {
    return {
      SubscriptionStatus,
    };
  },
})
export default class ConfirmSubscriptionPage extends Vue {
  @sSettings.State((state) => state.subscription.satisfied) satisfied: boolean;
  @sSettings.State((state) => state.subscription.state) state: string;
  @sSettings.State((state) => state.subscription.grace) grace: number;

  get hasGrace(): boolean {
    return (this.grace || 0) > 0;
  }
}
