var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[(
      _vm.state === _vm.SubscriptionStatus.Grace ||
      _vm.state === _vm.SubscriptionStatus.Pending ||
      (_vm.state === _vm.SubscriptionStatus.Confirmed && !_vm.satisfied)
    )?[_c('h1',{directives:[{name:"t",rawName:"v-t",value:('subscription.confirm.title'),expression:"'subscription.confirm.title'"}],staticClass:"title"}),_c('pending')]:(_vm.state === _vm.SubscriptionStatus.Cancelled)?[_c('h1',{directives:[{name:"t",rawName:"v-t",value:('subscription.cancelled.title'),expression:"'subscription.cancelled.title'"}],staticClass:"title"}),_c('cancelled')]:(
      (_vm.state === _vm.SubscriptionStatus.Confirmed ||
        _vm.state === _vm.SubscriptionStatus.Demo) &&
      _vm.satisfied
    )?[_c('h1',{directives:[{name:"t",rawName:"v-t",value:('subscription.confirmed.title'),expression:"'subscription.confirmed.title'"}],staticClass:"title"}),_c('router-link',{directives:[{name:"t",rawName:"v-t",value:('subscription.actions.continue'),expression:"'subscription.actions.continue'"}],staticClass:"button is-success",attrs:{"to":"/dashboard"}})]:(_vm.state === _vm.SubscriptionStatus.Suspended)?_c('p',{directives:[{name:"t",rawName:"v-t",value:('subscription.suspended.title'),expression:"'subscription.suspended.title'"}]}):[_vm._v(" Unknown account state ")]],2)}
var staticRenderFns = []

export { render, staticRenderFns }