


























import Vue from 'vue';
import { Component } from 'vue-property-decorator';
import { namespace } from 'vuex-class';
import { CompanyService } from '@/lib/services';
import { headOrSelf, sleep, unwrapError } from '@/lib/helpers';
import { SettingState } from '@/store/modules/settings/state';

const sSettings = namespace('settings');

@Component({
  components: {},
})
export default class Pending extends Vue {
  @sSettings.State('subscription')
  subscriptionState: SettingState['subscription'];

  loading: boolean = false;
  status: string = '';
  callback: boolean = false;
  callbackStatus: boolean = false;

  async mounted(): Promise<void> {
    if (this.$route.query['status']) {
      this.status = headOrSelf(this.$route.query['status'])!;
      this.callback = true;
      if (this.status === 'success') {
        this.loading = true;
        await this.confirm();
      }
    }
  }

  async confirm(): Promise<void> {
    this.callbackStatus = await CompanyService.subscriptionStatus();
    if (!this.callbackStatus) {
      // It didn't succeed. Give it a little bit (5s) and try again
      await sleep(5000);
      this.callbackStatus = await CompanyService.subscriptionStatus();
    }
    this.loading = false;
  }

  async startPayment(): Promise<void> {
    try {
      this.loading = true;
      const transaction = await CompanyService.subscribe();
      window.location.href = transaction.url;
    } catch (e) {
      this.loading = false;
      this.$toaster.error(
        this.$tc('messages.error.initiate.subscribe'),
        unwrapError(e),
      );
    }
  }

  get hasGrace(): boolean {
    return this.subscriptionState.grace > 0;
  }
}
