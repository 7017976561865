
































import Vue from 'vue';
import { Component, Prop } from 'vue-property-decorator';
import { namespace } from 'vuex-class';
import { SettingState } from '@/store/modules/settings/state';
import { AuthService } from '@/lib/services';
import { unwrapError } from '@/lib/helpers';

const sSettings = namespace('settings');

@Component({
  components: {},
})
export default class Cancelled extends Vue {
  @sSettings.State('subscription')
  subscriptionState: SettingState['subscription'];

  @Prop({ type: Boolean, default: false })
  hideButton: boolean;

  async reactivate(): Promise<void> {
    try {
      await AuthService.resumeAccount();
      this.$toaster.success(this.$tc('subscription.uncancelled.title'));
      window.location.href = '/';
    } catch (e) {
      this.$toaster.error('Error', unwrapError(e));
    }
  }

  get hasGrace(): boolean {
    return this.subscriptionState.grace > 0;
  }
}
